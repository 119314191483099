import React from "react"
// import { Link } from "gatsby"
import {graphql, Link} from 'gatsby'
// import Img from "gatsby-image"
import Layout from "../components/layout"


import ReactHtmlParser from 'react-html-parser';
import Disqus from 'disqus-react';
import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, PinterestShareButton, PinterestIcon} from 'react-share'
import {Helmet} from 'react-helmet'
import Img from 'gatsby-image'
import {Row,Col} from 'react-bootstrap'

class Blog extends React.Component {
  render() {
    const disqusShortname = process.env.DISQUS_SHORTNAME;
      const disqusConfig = {
          url: `${this.props.pageContext.baseurl}${this.props.path}`,
          identifier: this.props.pageContext.id ,
          title: this.props.pageContext.title,
      };
    return (
      <Layout searcharray={this.props.pageContext.searcharray} path={this.props.path} menu={this.props.pageContext.menu}>
        <Helmet>
          {this.props.pageContext.metainfo.map((item,i) => {
    if(item.key === 'title') {
      return (
        <title key={i}>{item.value}</title>
      )
    }
    else if(item.key === 'canonical') {
      return (
        <link key={i} rel="canonical" href={this.props.path} />
      )
    }

    else if(item.key === 'description') {
      return (
        <meta key={i} name="description" content={item.value}/>
      )
    }

    else {
      return null;
    }
  })}
        </Helmet>
      <section className="blogsection" >
        <h2 className="text-left">{this.props.pageContext.title}</h2>
        <div className="d-flex justify-content-between font-weight-bold mb-3" style={{fontSize:'0.8em'}}>
          <span>{this.props.pageContext.created}</span>
          <span><Link to={this.props.pageContext.category[0].entity.entityUrl.path}>{this.props.pageContext.category[0].entity.entityLabel}</Link></span>

        </div>
        <div className="imagecont mb-3">
          <PinterestShareButton className="pinterest" media={`${this.props.pageContext.baseurl}${this.props.data.drupal.nodeById.fieldMainImage.entity.thumbnail.imageFile.childImageSharp.fluid.src}`} url={`${this.props.pageContext.baseurl}${this.props.path}`}><PinterestIcon size={32} round={true} /></PinterestShareButton>
           <img style={{maxWidth:'100%'}} src={this.props.data.drupal.nodeById.fieldMainImage.entity.thumbnail.imageFile.childImageSharp.fluid.src} alt={this.props.data.drupal.nodeById.fieldMainImage.entity.thumbnail.alt}/>
        </div>
        { ReactHtmlParser(this.props.pageContext.body, {
          transform: (node)=> {


            // if(node.name === 'p' && node.children[0].name === 'img'){
            //     console.log(node)
            //   return this.props.data.drupal.fileQuery.entities.map((item) => {
            //
            //     if(item.url === `http://thebeautysyrop.gr${node.children[0].attribs.src}`) {
            //
            //       return (
            //         <div key={item.imageFile.id} >
            //         <Img  fluid={item.imageFile.childImageSharp.fluid} alt={node.children[0].attribs.alt}/>
            //         </div>
            //       )
            //     }
            //     else {
            //       return null
            //     }
            //   })
            //
            // }

            if(node.name === 'img'){

              return this.props.data.allFile.nodes.map((item) => {

                if(item.relativePath === node.attribs.src.replace('/sites/default/files/', '')) {

                  return (
                    <div key={item.id} className="imagecont">
                      <PinterestShareButton className="pinterest" media={`${this.props.pageContext.baseurl}${item.childImageSharp.fluid.src}`} url={`${this.props.pageContext.baseurl}${this.props.path}`}><PinterestIcon size={32} round={true} /></PinterestShareButton>

                        <img style={{maxWidth:"100%"}} src={item.childImageSharp.fluid.src} alt={node.attribs.alt}/>


                    </div>

                  )
                }
                else {
                  return null
                }
              })

            }


          }

        }) }

      </section>
      <div className="shareicons">
        <FacebookShareButton url={`${this.props.pageContext.baseurl}${this.props.path}`}><FacebookIcon size={32} round={true} /></FacebookShareButton>
        <TwitterShareButton url={`${this.props.pageContext.baseurl}${this.props.path}`}><TwitterIcon size={32} round={true} /></TwitterShareButton>
      </div>

      <section className="relatedblog">

        <h2 className="text-center">{`More ${this.props.pageContext.category[0].entity.entityLabel}`}</h2>

         <Row>
            {this.props.data.drupal.relatedblog.entities.map((item,i) => {
              return (
                <Col className="d-flex flex-column" key={i} md={4}>
                  <div>
                    <Link to={item.entityUrl.path}>
                      <Img style={{maxWidth:"100%"}} fixed={item.fieldMainImage.entity.thumbnail.imageFile.childImageSharp.fixed}/>
                      </Link>
                  </div>
                  <div>
                    <Link className="text-dark" to={item.entityUrl.path}><h3 className="text-center my-2">{item.title}</h3></Link>
                  </div>
                </Col>
              )
            } )}
         </Row>

      </section>
      <section id="disqusplace" className="disqus-section">




        <div className="dis-header d-flex align-items-center justify-content-center"><h4 className="mb-0">Comments</h4></div>
         <div className="discontainer">
        <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </div>
      </section>
      </Layout>
    )
  }
}




export default Blog

export const query = graphql`
query($parsed:[String], $id: String!, $catid: String!, $title: String!) {

  allFile(filter: {relativePath: {in: $parsed}}) {
    nodes {
      id
      relativePath
      childImageSharp {
    fluid(maxWidth: 1140,quality: 80) {
      ...GatsbyImageSharpFluid


      src
    }
  }
    }
  }

  drupal {



  nodeById(id: $id) {
... on DRUPAL_NodeBlog {
  entityLabel
  fieldMainImage {
    entity {
      thumbnail {
        url
        alt
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              src
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
}

relatedblog:nodeQuery(filter: {conjunction: AND, conditions: [{field: "type", value: "blog"}, {field: "field_category", value: [$catid]}, {field: "title", value: [$title], operator:NOT_EQUAL}]}, limit: 3) {
  entities {
    ... on DRUPAL_NodeBlog {
      title
      entityUrl{
        path
      }
      fieldMainImage {
        entity {
          thumbnail {
            url
            alt
            imageFile {
              childImageSharp {
                fixed(width: 480, height:350, quality: 80) {
                  ...GatsbyImageSharpFixed
                  src
                }
              }
            }
          }
        }
      }
    }
  }
}


}

}
`
